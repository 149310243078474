import { useContext, useEffect, useRef, useState } from "react";
import logo from "./img/logo.png";
import { PiCirclesThreePlusBold } from "react-icons/pi";
import { useSelector } from "react-redux";
import Cart from "../commonComponent/cardDetails";
import { Link, useNavigate } from "react-router-dom";
import AddressModal from "../commonComponent/addressModal";
import "./Nav.css"; // Import your custom CSS file
import ReactPixel from "react-facebook-pixel";

import ReactGA from "react-ga";
import { CartContext } from "../context/CartContext";
import SmallNavbar from "./SmallNavbar";
import TypingSearchInput from "../components/customInputs/TypingInput";
import { LOCAL_IMAGES } from "../utils/localimages";
import LoginModal from "../components/modals/LoginModal";
import { AuthContext } from "../context/AuthContext";
import Checkout from "../components/modals/Checkout";
import setAuthorizationToken from "../utils/commonFunction";
const TRACKING_ID = "G-56Q0JTSZKG";
ReactGA.initialize(TRACKING_ID);
const Navbar = () => {
  const navigate = useNavigate();
  const { isCartOpen, setIsCartOpen } = useContext(CartContext);

  const { showLoginModal, setShowLoginModal } = useContext(AuthContext);
  const [searchTerm, setSearchTerm] = useState("");
  const [isSticky, setIsSticky] = useState(false);
  const [isbrand, setBrand] = useState(false);
  const [options, setOptions] = useState(false);
  const [showCheckout, setShowCheckout] = useState(false)

  const cartLengthData = useSelector((store) => store.addProduct.cart);

  const token = useSelector((store) => store?.authReducer?.userData?.token);

  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);
  const [modalIsOpen, setIsOpen] = useState(false);
  const [test, setTest] = useState(isCartOpen);
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setBrand(false); // Close the dropdown when clicking outside
    }
  };  //const [islogin, setLogin] = useState(true);

  const [isSmallScreen, setIsSmallScreen] = useState(false);

  const handleOutsideClick = (event) => {
    if (!event.target.closest(".dropdown-menu")) {
      setIsOpen(false);
    }
  };


  useEffect(() => {
    if (modalIsOpen) {
      document.addEventListener("mousedown", handleOutsideClick);
    } 
    if(isbrand){
      document.addEventListener("mousedown", handleClickOutside);

    }
    else {
      document.removeEventListener("mousedown", handleClickOutside);

      document.removeEventListener("mousedown", handleOutsideClick);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);

      document.removeEventListener("mousedown", handleOutsideClick);
    };
  }, [modalIsOpen,isbrand]);

  

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);
  useEffect(() => {
    // Update isSmallScreen state on window resize
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1000);
    };

    // Initial check on component mount
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const onpress = () => {
    setIsOpen(false);
    setIsCartOpen(true);
  };



  function toggleOption() {
    setOptions(!options);
  }

  function handlemouseEnter() {
    setBrand(true);
  }
  function handlemouseLeave() {
    setBrand(false);
  }



  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      setIsSticky(scrollTop > 200); // Set isScrolled based on scroll position
      if (scrollTop > 200) {
        setIsSticky(true);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleSearch = (event) => {
    const value = event.target.value;
    if (value === "") {
      setSearchTerm("");
      navigate("/");
    } else {
      setSearchTerm(value);
      navigate(`/search${value ? `?text=${value}` : "text=''"}`);
    }
  };
  const linkData = [
    {
      pathname: "/best-sellers",
      text: "BestSellers",
    },
    {
      text: "Brands",
      onClick: handlemouseEnter, // Example onClick handler
      dropdown: true, // Indicating that this has a dropdown
    },
    {
      pathname: "/hair-care",
      text: "Haircare",
    },
    {
      pathname: "/skin-care",
      text: "Skincare",
    },
    {
      pathname: "/distributer",
      text: "Distribution",
    },

    {
      pathname: "/product-combo",
      text: "Product combo",
    },
    {
      pathname: "/contactus",
      text: "Contact Us",
    },
  ];
  const handleProfileClick = () => {
    if (token) {
      navigate("/myprofile");
    } else {
      setShowLoginModal(true);
    }
  }
  const onClickPayment = () => {

    ReactPixel.init("976335490593876");
    // Track page view event
    ReactPixel.pageView();
    if (!token) {
      setIsCartOpen(false);
      setShowLoginModal(true);
    }
    else {
      setShowCheckout(true)

    }
  }

  const brandData = [
    {
      to: "/skinco",
      src: LOCAL_IMAGES.SKINCO_IMG,
      alt: "Skinco logo",
    },
    {
      to: "/spring",
      src: LOCAL_IMAGES.SPRING_IMG,
      alt: "Spring logo",
    },
    {
      to: "/argatin",
      src: LOCAL_IMAGES.ARGATIN_IMG,
      alt: "Argatin logo",
    },
  ];

  return (
    <>
      <div className="bg-black  relative">
        {isSmallScreen && <SmallNavbar onClickPayment={onClickPayment} setShowModal={setShowLoginModal} />}
        {!isSmallScreen && (
          <>
            <div className="flex w-full border border-b border-white/20 border-x-0 border-t-0 py-4 items-center justify-between px-[2%] xl:px-[5%]  xl:pr-9 ">
              <div className=" w-full xl:pl-[4%] flex gap-6 xl:gap-20 items-center">
                <div className=" ">
                  <Link
                    to={"/"}
                    onClick={() => {
                      setIsOpen(false);
                    }}
                    className="h-full w-full"
                  >
                    <img
                      loading="lazy"
                      className="h-[35px] min-w-[140px]"
                      src={logo}
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="flex gap-6 xl:gap-9   items-center">
                  {brandData.map((brand, idx) => {
                    const { to, src, alt } = brand;
                    return (
                      <Link to={to} className="">
                        <img
                          loading="lazy"
                          className={` ${idx > 0 ? "-mb-2" : "mb-0"
                            } h-[22px] xl:h-[25px] w-[90px] xl:w-[100px]`}
                          src={src}
                          alt={alt}
                        />
                      </Link>
                    );
                  })}
                  <TypingSearchInput
                    searchTerm={searchTerm}
                    handleSearch={handleSearch}
                  />
                </div>
              </div>
              <div className="flex items-center gap-6 cursor-pointer">




                {/* <div className="cart-container"> */}
                <div className='flex relative justify-between items-center gap-x-5'>
                  <button className=''
                    onClick={handleProfileClick}
                  >
                    <svg
                      width={25}
                      height={25}
                      className='text-white w-[27px] h-[27px] inline-block align-middle'
                      viewBox="0 0 27 27"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                      id="svgkp"
                    >
                      <path
                        d="M22.9129 12.935L13.7571 23.0474C13.5348 23.2929 13.1284 23.1084 13.1669 22.7794L14.0816 14.9731H10.6991C10.4034 14.9731 10.2484 14.6219 10.4478 14.4035L20.3133 3.59739C20.5589 3.32834 20.9984 3.58134 20.8891 3.92887L18.2354 12.3664H22.6607C22.9557 12.3664 23.1109 12.7163 22.9129 12.935Z"
                        fill="#FEA203"
                      />
                      <path
                        id="svgkp-path"
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M16.6079 5.35819C16.4805 5.1933 16.3421 5.03582 16.1932 4.8869C15.2702 3.96387 14.0183 3.44531 12.7129 3.44531C11.4075 3.44531 10.1556 3.96387 9.2326 4.8869C8.30957 5.80993 7.79102 7.06183 7.79102 8.36719C7.79102 9.67255 8.30957 10.9244 9.2326 11.8475C9.48368 12.0986 9.75909 12.3197 10.0533 12.5086L11.0235 11.4503C10.7335 11.2914 10.4649 11.0911 10.227 10.8531C9.56766 10.1938 9.19727 9.29959 9.19727 8.36719C9.19727 7.43479 9.56766 6.54057 10.227 5.88127C10.8863 5.22196 11.7805 4.85156 12.7129 4.85156C13.6453 4.85156 14.5395 5.22196 15.1988 5.88127C15.3636 6.04604 15.5103 6.22549 15.6377 6.41654L16.6079 5.35819ZM20.6413 18.6497L19.6746 19.7132C20.1676 20.4122 20.4473 21.2264 20.4473 22.0781V23.8359C20.4473 24.2243 20.7621 24.5391 21.1504 24.5391C21.5387 24.5391 21.8535 24.2243 21.8535 23.8359V22.0781C21.8535 20.7863 21.4016 19.6103 20.6413 18.6497ZM12.3111 17.5078H10.3026C7.27113 17.5078 4.97852 19.6394 4.97852 22.0781V23.8359C4.97852 24.2243 4.66372 24.5391 4.27539 24.5391C3.88707 24.5391 3.57227 24.2243 3.57227 23.8359V22.0781C3.57227 18.6922 6.67684 16.1016 10.3026 16.1016H12.4885L12.3111 17.5078Z"
                        fill="currentColor"
                        stroke="currentColor"
                      />
                    </svg>

                  </button>

                  <div onClick={onpress} className='relative cursor-pointer'>
                    <p className="cart-link__bubble"><span className="text-white relative">{cartLengthData?.length}</span></p>
                    <button  >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        aria-hidden="true"
                        focusable="false"
                        role="presentation"
                        className="icon icon-cart icon--line w-[27px] h-[27px] inline-block align-middle text-white"
                        fill='currentColor'
                        viewBox="0 0 64 64"
                        width={20}
                        height={20}
                      >
                        <path
                          d="M14 17.44h46.79l-7.94 25.61H20.96l-9.65-35.1H3"
                          strokeMiterlimit={10}
                          fill="none"
                          stroke="currentColor"
                        />
                        <circle cx={27} cy={53} r={2} fill="currentColor" stroke="currentColor" />
                        <circle cx={47} cy={53} r={2} fill="currentColor" stroke="currentColor" />
                      </svg>
                    </button>

                  </div>
                </div>

                {isCartOpen && (
                  <Cart
                    setShowCart={setIsCartOpen}
                    isCartOpen={isCartOpen}
                    onClickPayment={onClickPayment}
                    setTest={setTest}
                    test={test}
                  />
                )}

                {/* </div> */}
                <PiCirclesThreePlusBold
                  className="menu-icon"
                  onClick={toggleOption}
                />
              </div>
            </div>

            <div
              className={`absolute flex -bottom-[44px]   w-full px-[5%] xl:px-[10%] bg-black font-Inter mx-auto items-center  justify-around  ${isSticky ? "fade-out-top" : ""
                }`}
            >
              {" "}
              {linkData.map((link, index) => {
                if (link.dropdown) {
                  return (
                    <button
                      key={index}

                      className=" relative border-b-2 py-3 border-transparent hover:border-white  hover:border-b-2  text-white tracking-[0.2em]  whitespace-nowrap text-[12px] uppercase  transition duration-150 ease-in-out  w-fit    "
                      onClick={() => setBrand(!isbrand)}
                      onMouseEnter={link.onClick}
                    >
                      {link.text}
                      {isbrand && (
                        <div
                          ref={dropdownRef}
                          onMouseLeave={handlemouseLeave}
                          className="absolute min-w-[150px]  flex flex-col text-black shadow-md border -left-[20px] -bottom-[131px] z-10 bg-white p-3"
                        >
                          <Link
                            to="/spring"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Spring H2O
                          </Link>
                          <Link
                            to="/argatin"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Argatin
                          </Link>
                          <Link
                            to="/skinco"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Skinco
                          </Link>
                          <Link
                            to="/loreal"
                            className="text-sm text-gray-600 hover:underline hover:underline-black text-start pb-2 last:pb-0"
                            onClick={() => setBrand(false)}
                          >
                            Loreal
                          </Link>



                        </div>
                      )}
                    </button>
                  );
                }
                return (
                  <Link
                    key={index}
                    to={link.pathname}
                    className="  text-white border-b-2 py-3 border-transparent hover:border-white  hover:border-b-2 tracking-[0.2em]  whitespace-nowrap text-[12px] uppercase  transition duration-150 ease-in-out  w-fit    "
                    style={{ textDecoration: "none" }}
                  >
                    {link.text}
                  </Link>
                );
              })}
            </div>
          </>
        )}
        {showCheckout && <Checkout show={showCheckout} setShow={setShowCheckout} />
        }
        {test && <AddressModal addressmodal={test} setAddressModal={setTest} />}
      </div>
      <LoginModal show={showLoginModal} setShow={setShowLoginModal} />
    </>
  );
};

export default Navbar;
