import axios from "axios";
import { store } from "../Redux/store/store";
const BASE_URL = "https://prosaloncart.com/";
// const BASE_URL = "https://prosaloncart.com/";
// const BASE_URL = "http://192.168.2.33:4001/";
// const BASE_URL = "http://192.168.3.36:4001/";
export const BASE_URL2 = "https://crm.smartsalon.in/";
// export const BASE_URL= 'http://192.168.2.155:4001/';

const authToken = store.getState();

const token = authToken.authReducer?.userData?.token;
// 

const instance = axios.create({
  baseURL: BASE_URL,
  timeout: 30000,
  headers: {
    "X-Custom-Header": "foobar", // Custom headers if needed
    "Content-Type": "application/json", // Sample content type header
    Authorization: `Bearer ${token}`,
  },
});

const setAuthorizationToken = (auth_token) => {
  if (auth_token) {
    instance.defaults.headers.common["Authorization"] = auth_token;
  }
};
const postApiData = (
  endpoint,
  apidata,
  success,
  failure,
  isFailure = false
) => {
  const authToken = store.getState();
  const token = authToken.authReducer.userData?.token;
  const instance = axios.create({
    baseURL: BASE_URL,
    timeout: 30000,
    headers: {
      "X-Custom-Header": "foobar", // Custom headers if needed
      "Content-Type": "application/json", // Sample content type header
      Authorization: `Bearer ${token}`,
    },
  });
  instance
    .post(endpoint, apidata)
    .then((res) => {
      
      success(res?.data?.data);
    })
    .catch((error) => {
      
      if (isFailure) {
        failure(error);
      }
    });
};
const getApiCall = (endpoint, success, failure, isCrm = false) => {
  // 
  const authToken = store.getState();
  const token = authToken.authReducer?.userData?.token;

  const instance = axios.create({
    baseURL: isCrm ? BASE_URL2 : BASE_URL,
    timeout: 30000,
    headers: {
      "X-Custom-Header": "foobar", // Custom headers if needed
      "Content-Type": "application/json", // Sample content type header
      Authorization: `Bearer ${token}`,
    },
  });

  instance
    .get(endpoint)
    .then((res) => {
      
      success(res?.data?.data);
    })
    .catch((error) => {
      if (failure) {
        failure(error);
      }
    });
};

function formatDateToFull(dateString,full=true) {
  if (dateString) {
    const [datePart, timePart] = dateString.split("T");
    const [year, month, day] = datePart.split("-");
    const [hour, minute] = timePart.split(":");

    // Convert month from "08" to "Aug" or any other short form
    const monthNames = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthShort = monthNames[parseInt(month, 10) - 1];
    
    if(!full){
      const formattedDate = `${parseInt(day, 10)} ${monthShort} ${year} `;
       return formattedDate
    }
    // Format the time as "08:34 AM/PM"
    let hourInt = parseInt(hour, 10);
    const period = hourInt >= 12 ? "PM" : "AM";
    hourInt = hourInt % 12 || 12; // Convert to 12-hour format
    const formattedTime = `${hourInt}:${minute} ${period}`;

    // Format the full date as "7 Aug 2024 08:34 AM"
    const formattedDate = `${parseInt(day, 10)} ${monthShort} ${year} ${formattedTime}`;
    return formattedDate;
  }
  return dateString;
}
const getStatusText=(key)=>{
  switch(key){
    case 1:
      return "Order Confirmed";
    case 2:
      return "Canceled";
    case 3:
      return "Delivered";
    case 4:
      return "Return To Origin";
    case 5:
      return "Pending";
    case 6:
      return "Undelivered";
    case 7:
      return "Shipped";
    default:
      return "Order Confirmed";
  }
}
const getStatusColor = (key) => {
  switch (key) {
    case 1:
      return "text-green-600";  // Order Confirmed (green)
    case 2:
      return "text-red-600";    // Canceled (red)
    case 3:
      return "text-blue-600";   // Delivered (blue)
    case 4:
      return "text-gray-600";   // Return To Origin (gray)
    case 5:
      return "text-yellow-500"; // Pending (yellow)
    case 6:
      return "text-orange-500"; // Undelivered (orange)
    case 7:
      return "text-indigo-600"; // Shipped (indigo)
    default:
      return "text-gray-600";   // Default color (gray)
  }
}
export const brandsImg = [
  {
    brandUrl: "https://smartsalon.in/static/media/lorealbrand.7105ca5a1326661ed221.png",
  },
  {
    brandUrl: "https://smartsalon.in/static/media/Kerastase.7827603681465f1c8893.jpg",
  },

  {
    brandUrl: "https://smartsalon.in/static/media/thalgo.410a806831282fd090db.jpg",
  },
  {
    brandUrl: "https://smartsalon.in/static/media/argatin.f684fbb9f369b46a30d1.jpg",
  },
  
];

export { postApiData, getApiCall, instance,formatDateToFull,getStatusText,getStatusColor };
