import Modal from "../modal/Modal";
import logo from "../../Navbar/img/logo.png";
import { IoMdClose } from "react-icons/io";
import { BiSolidPyramid } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "./LoginModal.css";
import { RxCountdownTimer } from "react-icons/rx";
import Spinner from "../loader/Spinner";
import toast from "react-hot-toast";
import { useDispatch } from "react-redux";
import { postApiData } from "../../utils/services";
import { userDetails } from "../../Redux/actions";

const LoginModal = ({ show, setShow }) => {
  return (
    <>
      <Modal show={show} setShow={setShow}>
        <ChildComponent />
      </Modal>
    </>
  );
};

const ChildComponent = ({ show, closeModal, modalRef }) => {
  const numberRegex = /^[6-9]\d{9}$/;
  const phoneRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [phoneNumber, setPhoneNumber] = useState(null);
  const [isPhoneEntered, setIsPhoneEntered] = useState(false);
  const [isOtpSending, setIsOtpSending] = useState(false);
  const [isOtpValid, setIsOtpValid] = useState(true);
  const [otp, setOtp] = useState(new Array(4).fill("")); // For a 4-digit OTP
  const [timer, setTimer] = useState(60); // 60 seconds countdown
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const handleChange = (e) => {
    const { value } = e.target;
    if (value?.length <= 10) {
      setPhoneNumber(value);
      validatePhone(value);
    }
  };

  const validatePhone = (value) => {
    const isValid = numberRegex.test(value);
    if (!isValid) {
      setIsPhoneEntered(false);
    } else {
      sendOtp(value);
    }
  };

  const handleOtpChange = (element, index) => {
    if (isNaN(element.value)) return; // Only allow numbers
    // If the value is not empty, update the OTP value

    if (element.value) {
      setOtp((prev) =>
        prev.map((elm, idx) => (idx === index ? element.value : elm))
      );

      // Move to the next input if the current one is filled
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    } else {
      // Clear the current input and move to the previous one if backspace is pressed
      setOtp((prev) => prev.map((elm, idx) => (idx === index ? "" : elm)));

      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    }
  };

  const handleKeyDown = (e, index) => {
    // Check for backspace key
    if (e.key === "Backspace" && !otp[index]) {
      // If input is empty, move to the previous input
      if (e.target.previousSibling) {
        e.target.previousSibling.focus();
      }
    }
  };

  const sendOtp = (phoneNumber) => {
    let data = {
      phoneNumber,
    };
    setIsOtpSending(true);

    postApiData(
      "/user/sendOtp",
      data,
      (response) => {

        setIsOtpSending(false);
        setIsResendDisabled(true);
        setTimer(60);
        setIsPhoneEntered(true);

        toast.success("Otp send Sucessfully!");
      },
      (error) => {
        setIsOtpSending(false);


      },
      true
    );
  };

  const productFeatures = [
    {
      title: "No Sulphates",
      description:
        "Free from harsh sulphates, our products gently cleanse without stripping your hair or skin of essential moisture.",
    },
    {
      title: "No Parabens",
      description:
        "Our formulas are paraben-free, ensuring safe and long-lasting care without harmful preservatives.",
    },
    {
      title: "Derma Tested",
      description:
        "Dermatologically tested for safety and effectiveness, our products are suitable for all skin types.",
    },
  ];

  useEffect(() => {
    // Timer logic
    const interval = setInterval(() => {
      if (timer > 0) {
        setTimer(timer - 1);
      } else {
        setIsResendDisabled(false);

        clearInterval(interval);
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [timer]);

  const handleEdit = () => {
    setOtp(new Array(4).fill("")); // Clear OTP inputs

    setIsPhoneEntered(false);
    if (phoneRef.current) phoneRef.current.focus();
  };

  const handleResendOtp = () => {
    setOtp(new Array(4).fill("")); // Clear OTP inputs
    setTimer(60); // Reset the timer
    setIsResendDisabled(true);
    sendOtp(phoneNumber); // Disable resend button again
    // Trigger your resend OTP logic here (e.g., API call)
  };
  const handleSubmit = () => {
    validatePhone(phoneNumber);
  };

  const isOtpEmpty = useMemo(() => {
    return otp.some((elm) => elm === "");
  }, [otp]);

  const handleOtpSubmit = useCallback(() => {
    const data = {
      phoneNumber: parseInt(phoneNumber),
      otp: parseInt(otp.join("")), // Combine the OTP array into a string
    };

    //   toast.dismiss();

    //   toast.success("Logged in successfully!");
    //   closeModal();
    //   setTimeout(() => {
    //     setOtp(new Array(4).fill(""));

    //     setIsOtpValid(true);
    //     setIsPhoneEntered(false);
    //     setPhoneNumber(null);
    //   }, 200);

    //   // Clear OTP inputs
    //   navigate("/checkout");
    // }, 1000);

    postApiData(
      "/user/login",
      data,
      (response) => {
        const payload ={
          token:response,
          phoneNumber,
       
        }
        dispatch(userDetails(payload));
        toast.dismiss();

        closeModal();
        setTimeout(() => {
          setOtp(new Array(4).fill(""));

          setIsOtpValid(true);
          setIsPhoneEntered(false);
          setPhoneNumber(null);
        }, 200);

        navigate("/");

        toast.success("Logged in successfully!");

      },

      () => {
        setIsOtpValid(false);
        toast.dismiss();
        toast.error("Otp incorrect!");
        setTimeout(() => {
          toast.dismiss();
        }, 500);
      },
      true

    );
  }, [otp, phoneNumber, navigate]);

  useEffect(() => {
    const isOtpFilled = otp.every((elm) => elm !== "");
    if (isOtpFilled) {
      handleOtpSubmit();
    }
  }, [otp]);


  return (
    <>
      <div className="relative h-full w-full font-[inherit] z-[100] bg-black/30 ">
        {/* Modal content */}
        <div
          ref={modalRef}
          className="relative  h-fit top-[10%] bottom-[10%] xl:top-[20%] w-[95%] sm:w-[400px] xl:w-[65%] rounded-[1rem] border border-white/40 dark     mx-auto"
        >
          {/* Modal header */}
          <div className="p-3 xl:p-6">
            <div className="flex flex-col xl:flex-row gap-6 w-full">
              <div className="w-full xl:w-[60%] 2xl:w-[65%] flex flex-col items-center text-center ">
                <div className="mb-3">
                  <img
                    loading="lazy"
                    className="h-[25px] min-w-[140px]"
                    src={logo}
                    alt="logo"
                  />
                </div>
                <h2 className="w-[80%] mb-3 xl:mb-9 text-white text-xs xl:text-lg 2xl:text-xl font-semibold">
                  Delivering Premium Salon Products for Professionals and Home
                  Beauty Enthusiasts
                </h2>
                <div className="flex flex-row items-center gap-5 overflow-hidden  justify-evenly w-full xl:mx-6">
                  {productFeatures.map((feature, idx) => {
                    const { title, description } = feature;
                    return (
                      <div
                        key={idx}
                        className="border w-full flex flex-col   items-center gap-1 xl:gap-1 text-white border-white rounded-[1rem] h-full p-2 xl:p-4"
                      >
                        <h2 className="font-bold w-[20px]  xl:w-auto text-sm xl:text-4xl flex items-center justify-center  text-center ">
                          <BiSolidPyramid />
                        </h2>
                        <h2 className="font-bold text-[11px] xl:text-sm ">
                          {title}
                        </h2>
                        <p className="text-xs hidden xl:block">{description}</p>
                      </div>
                    );
                  })}
                </div>
              </div>
              <div className="w-full h-[100%] overflow-y-auto xl:w-[40%] 2xl:w-[35%]  bg-white rounded-[1rem] p-3 xl:p-6">
                {!isPhoneEntered ? (
                  <>
                    {" "}
                    <div className="text-center mb-5">
                      <h2 className="text-black text-xl font-bold ">Unlock</h2>
                      <h2 className="text-black text-xl font-bold ">
                        Superior Discounts
                      </h2>
                    </div>
                    <div>
                      <div className="mb-5">
                        <label
                          htmlFor="phone"
                          className="block text-center mb-3 text-sm font-medium text-gray-900"
                        >
                          Enter Mobile Number
                        </label>
                        <div className="relative w-full">
                          <input
                            ref={phoneRef}
                            type="number"
                            id="phone"
                            value={phoneNumber}
                            onChange={handleChange}
                            className="bg-gray-50 border border-gray-300 outline-none text-gray-900 text-sm rounded-lg focus:shadow-md  focus:border-black block w-full p-3"
                            placeholder="Enter Mobile Number"
                            pattern="[0-9]{10}"
                            required
                          />
                          {isOtpSending && (
                            <div className="absolute right-2 top-2">
                              <Spinner />
                            </div>
                          )}{" "}
                        </div>
                      </div>
                      <button
                        onClick={handleSubmit}
                        className="bg-black w-full rounded-md h-[40px] flex items-center justify-center text-white text-xs font-semibold mb-9"
                      >
                        Submit
                      </button>

                      <span className="text-[11px] font-bold w-[80%] mx-auto">
                        I accept that I have read & understood{" "}
                        <Link
                          to="PrivacyPolicy"
                          target="_blank"
                          className="underline "
                        >
                          {" "}
                          Privacy Policy
                        </Link>{" "}
                        and{" "}
                        <Link
                          target="_blank"
                          to="TermsAndCondition"
                          className="underline "
                        >
                          T&Cs
                        </Link>
                        .
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="text-center mt-3  h-full  xl:mt-9 ">
                      <h2 className="text-black text-xl mb-2 font-bold ">
                        OTP Verification
                      </h2>
                      <h2 className="text-gray-500 text-md ">
                        We have sent verification code to{" "}
                      </h2>
                      <div className="text-gray-500 font-semibold text-lg mb-5 flex items-center justify-center gap-2">
                        <span>+91</span> {phoneNumber}{" "}
                        <button
                          onClick={handleEdit}
                          className="w-[40px] h-[22px] outline-none flex items-center justify-center text-[12px] text-green-600 rounded-[0.8rem] border border-green-600"
                        >
                          Edit
                        </button>
                      </div>
                      <div>
                        <div className="flex justify-center gap-2 mb-3">
                          {otp.map((data, index) => (
                            <input
                              key={index}
                              type="text"
                              inputmode="numeric"
                              maxLength="1"
                              value={data}
                              onKeyDown={(e) => handleKeyDown(e, index)}
                              onChange={(e) => handleOtpChange(e.target, index)}
                              onFocus={(e) => e.target.select()}
                              className={`ap-otp-input focus:shadow-md  w-1/4 p-2 rounded-lg ${isOtpValid
                                ? "border-gray-300"
                                : "border-rose-600"
                                } border  bg-white shadow-sm h-9 xl:h-16 text-3xl font-medium text-center text-blue-900 focus:outline-none focus:border-black`}
                            />
                          ))}
                        </div>
                        <div className="flex items-center justify-center gap-1 mb-3">
                          <button>
                            <RxCountdownTimer size={15} />
                          </button>
                          <button
                            disabled={isResendDisabled}
                            onClick={handleResendOtp}
                            className={`text-xs ${isResendDisabled ? "" : "underline"
                              } `}
                          >
                            <span>Resend OTP </span>{" "}
                            {isResendDisabled && (
                              <>
                                {" "}
                                <span>in {timer}</span>{" "}
                                <span>
                                  <span>Sec</span>
                                </span>
                              </>
                            )}
                          </button>
                        </div>
                        <button
                          disabled={isOtpEmpty}
                          onClick={handleOtpSubmit}
                          className={` ${!isOtpEmpty
                            ? "bg-gray-200 cursor-not-allowed"
                            : "bg-black cursor-pointer"
                            } w-full rounded-md h-[40px] flex items-center justify-center text-white text-xs font-semibold mb-9`}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="-top-[40px] right-0 md:-right-[20px] absolute z-100 ">
            <button
              onClick={closeModal}
              className="hover:bg-white rounded-md hover:text-black text-white p-1"
            >
              <IoMdClose size={25} />
            </button>
          </div>
          {/* Modal body */}
        </div>
      </div>
    </>
  );
};

export default LoginModal;
