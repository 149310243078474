import "./Cart.css";
import { MdClose } from "react-icons/md";
import { BsCartX } from "react-icons/bs";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { FaLongArrowAltRight } from "react-icons/fa";

import {
  cartRemove,
  cartTotalpayableAmount,
  decreaseQuantity,
  postProductData,
} from "../../Redux/actions";
import { useNavigate } from "react-router-dom";

import setAuthorizationToken from "../../utils/commonFunction";
import { FaIndianRupeeSign } from "react-icons/fa6";
import Checkout from "../../components/modals/Checkout";

const Cart = ({
  setShowCart,
  test,
  onClickPayment
 
}) => {
  const[showCheckout,setShowCheckout]=useState(false)
  const token = useSelector((store) => store?.authReducer?.userData?.token);
  useEffect(() => {
    if (token) {
      setAuthorizationToken(token);
    }
  }, []);

  const guestUserCartDetails = useSelector((state) => state.addProduct.cart);
  const cartLength = guestUserCartDetails?.length || 0;
  const totalValue = guestUserCartDetails?.reduce((acc, cuu) => {
    acc = acc + cuu.price * cuu.quantity;
    return acc;
  }, 0);
  

  const total = guestUserCartDetails?.reduce(
    (acc, item) => acc + item.quantity * item.price,
    0
  );

  const [updatedAmount, setupdatedAmount] = useState(total);
 
  const [shipping, setShipping] = useState(50);
  const dispatch = useDispatch();
  const navigate = useNavigate();
 

  const incrementQuantity = (item, quantity) => {
    dispatch(postProductData(item, quantity));
  };
  const decrementQuantity = (item, quantity) => {

    dispatch(decreaseQuantity(item, quantity));
  };

  const removeitem = (item) => {
    dispatch(cartRemove(item));
  };
  const returnShopPress = () => {
    navigate("/");
    setShowCart(false);
  };

 

  const closeclick = () => {
    dispatch(cartTotalpayableAmount(totalValue));
    setShowCart(false);
  };
  useEffect(() => {
    if (total < 500) {
      const updatedamountwithDelivery = total + 50;
      setShipping(50);
      setupdatedAmount(updatedamountwithDelivery);
    } else {
      setShipping(0);
      setupdatedAmount(total);
    }
  }, [total]);
  return (
    <div className="cart-container font-Inter text-black">
      <div
        // className="cart-panel"
        className={test ? "cart-panelstyleadd" : "cart-panel"}
      >
        <div className="opac-layer" onClick={closeclick}></div>
        <div className="cart-content  bg-light-gray   w-full h-full min-w-[300px] md:min-w-[350px] overflow-hidden md:w-[30%] xl:w-[25%] flex flex-col justify-between">
          <div className="">
            <div className="flex bg-white  py-[10px] px-[20px] justify-between items-center">
              <span className="text-[18px] font-[600] flex gap-2"><span>Your Cart</span><span>(<span>{cartLength > 9 ? "9+" : cartLength}</span><span className="pl-1">items</span>) </span></span>
              <span className="text-black  text-xl font-semibold" onClick={closeclick}>
                <MdClose />
              </span>
            </div>
            <div className="bg-black rounded-b-[15px] text-white text-center text-[14px] font-[600] py-[5px] px-[10px]">
              <p>Unlock Upto 35% Off on All Products</p>
            </div>
            <div className="max-h-[calc(100vh-192px)] p-[10px]  overflow-y-auto overflow-x-hidden">
            <div className=" rounded-[12px] bg-white shadow border">
              {cartLength > 0 ? (
                guestUserCartDetails?.map((item, index) => {

                  return (
                    <div className="m-[10px] border-b last:border-b-0 pb-[10px]   gap-3 flex items-center justify-between bg-white">
                     <div className="flex items-center justify-center gap-3">
                      <div>
                        <img
                          loading="lazy"
                          src={item?.images?.length > 0 ? item?.images[0] : ""}
                          className=" h-[90px] border rounded-[12px] aspect-square max-w-max"
                          alt="product"
                        />
                      </div>
                      <div className=" mb-auto">
                        <div className="flex max-w-md gap-2">
                          <span className="line-clamp-2  text-[11px] font-[500]   ">
                            {item?.name}
                          </span>

                        </div>

                        <div className="flex items-center justify-center  w-fit gap-2 mt-3">
                          <div className="bg-gray-100 text-[10px] font-[600] p-1 flex items-center justify-center w-fit rounded-[10px]">
                            {item.quantity === 1 ? (
                              <button
                                // onClick={() => decrementQuantity(item,-1)}
                                className=" border-r flex items-center justify-center border-gray-200 px-[10px] cursor-not-allowed"
                                disabled
                              >
                                -
                              </button>
                            ) : (
                              <button
                                onClick={() =>
                                  decrementQuantity(item, item.quantity)
                                }
                                className=" border-r flex items-center justify-center border-gray-200 px-[10px] cursor-pointer"
                              >
                                -
                              </button>
                            )}

                            <div className="px-[10px]" >
                              {item?.quantity}
                            </div>

                            <button
                              onClick={() => incrementQuantity(item, 1)}
                              className="border-l flex items-center justify-center border-gray-200 px-[10px] cursor-pointer"
                            >
                              +
                            </button>
                          </div>
                          <button className="text-[16px]" onClick={() => removeitem(item)} >
                          <RiDeleteBin6Fill />
                          </button>
                          <div
                          //   onClick={() => removeitem(item)}
                          >
                          </div>
                        </div>
                      </div>
                      </div>
                      <p className="whitespace-nowrap mb-auto font-[600] text-[12px]">₹ {item.price * item.quantity}</p>

                      {/* <MdClose
                            style={{ marginRight: "15px", marginTop: "10px" }}
                            onClick={() => removeitem(item)}
                          /> */}
                    </div>
                  );
                })
              ) : (
                <div className="font-Inter empty-cart">
                  <BsCartX />
                  <span>No products in the cart</span>
                  <button className="bg-black text-white h-[38px] rounded-[5px]  w-[160px] flex items-center justify-center  text-[14px] " onClick={returnShopPress}>
                    RETURN TO SHOP
                  </button>
                </div>
              )}
            </div>
            </div>
          </div>

          {guestUserCartDetails?.length > 0 && <React.Fragment>
            {/* <CartItem /> */}
            <div className="bg-white p-[20px] rounded-bl-[10px] overflow-hidden rounded-t-[10px]">
              <div className="flex justify-between items-center">
                <span className="text-[13px] font-[400]"> Estimated Total:</span>
                <div className="flex items-center justify-center">
                  {/* <span> */}
                  <FaIndianRupeeSign />
                  <p className="text-[16px] font-[600]">
                    {totalValue?.toFixed(2)||0}
                  </p>
                  {/* </span> */}
                </div>

             
              </div>
              <div className="button">

                <button className="bg-black text-white font-[500] text-[12px] flex items-center justify-center gap-3 rounded-[5px] p-[10px] w-full my-[10px]" onClick={onClickPayment}>
                <span>  Continue</span>
                <span>  <FaLongArrowAltRight />
</span>
                
                </button>
              </div>
            </div>
          </React.Fragment>}
        </div>
      </div>
{showCheckout&&      <Checkout show={showCheckout} setShow={setShowCheckout}/>
}      {/* {
        <AddressModal addressmodal={addressmodal}/>
      } */}
    </div>
  );
};

export default Cart;
