import "./App.css";
import { lazy, Suspense, useContext } from "react";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import { CartProvider } from "./context/CartContext";
import ReactGA from "react-ga";
import { useEffect } from "react";
import Navbar from "./Navbar/Navbar";
import NewFooter from "./components/footer/newFooter";
import OverlayLoader from "./components/loader/OverlayLoader";
import Training from "./Training/Training";
import { useSelector } from "react-redux";
import { AuthContext, AuthContextProvider } from "./context/AuthContext";

const lazyRetry = function (componentImport) {
  return new Promise((resolve, reject) => {
    const hasRefreshed = JSON.parse(
      window.sessionStorage.getItem("retry-lazy-refreshed") || "false"
    );

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem("retry-lazy-refreshed", "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasRefreshed) {
          // not been refreshed yet
          window.sessionStorage.setItem("retry-lazy-refreshed", "true");
          return window.location.reload(); // refresh the page
        }
        reject(error);
      });
  });
};
const Home = lazy(() => lazyRetry(() => import("./Auth/auth")));
// import store from './Redux/store/store';
const Skinco = lazy(() => lazyRetry(() => import("./components/skinco")));
const Cartdescription = lazy(() =>
  lazyRetry(() => import("./Cartdescription/Cartdescription"))
);
const Haircareallproducts = lazy(() =>
  lazyRetry(() => import("./Haircareallproducts/Haircareallproducts"))
);
const Skincareallproducts = lazy(() =>
  lazyRetry(() => import("./Skincareallproducts/Skincareallproducts"))
);
const Argatin = lazy(() => lazyRetry(() => import("./components/argatin")));
const SignUp = lazy(() => lazyRetry(() => import("./Login/Login")));
const ResetPassword = lazy(() =>
  lazyRetry(() => import("./components/resetpassword"))
);
const Menicure = lazy(() => lazyRetry(() => import("./components/menicure")));
const FacialKit = lazy(() => lazyRetry(() => import("./components/facialkit")));
const Profile = lazy(() => lazyRetry(() => import("./components/profile")));
const Faq = lazy(() => lazyRetry(() => import("./components/faq")));
const PrivacyPolicyComponent = lazy(() =>
  lazyRetry(() => import("./components/privacyPolicy"))
);
const TermsAndCondition = lazy(() =>
  lazyRetry(() => import("./components/termsAndcondition"))
);
const ReturnRefund = lazy(() =>
  lazyRetry(() => import("./components/refundAndReturn"))
);
const AboutUs = lazy(() => lazyRetry(() => import("./components/aboutus")));
const Distributer = lazy(() =>
  lazyRetry(() => import("./components/distributerpage"))
);

//import LoginForm from "./Loginform/Loginform";
const Productformdetails = lazy(() =>
  lazyRetry(() => import("./Productformdetails/Productformdetails"))
);
const Editform = lazy(() => lazyRetry(() => import("./Editform/Editform")));
const Argatinshampoo = lazy(() =>
  lazyRetry(() => import("./Argatinshampoo/Argatinshampoo"))
);
const Hairconditioner = lazy(() =>
  lazyRetry(() => import("./Hairconditioner/Hairconditioner"))
);
const Hairoil = lazy(() => lazyRetry(() => import("./Hairoil/Hairoil")));
const Argatinkeratintreatment = lazy(() =>
  lazyRetry(() => import("./Argatinkeratintreatment/Argatinkeratintreatment"))
);
const Listcombo = lazy(() => lazyRetry(() => import("./productcombo")));
const Productupdateform = lazy(() =>
  lazyRetry(() => import("./productupdateform/Productupdateform"))
);
const Formupdate = lazy(() =>
  lazyRetry(() => import("./formupdate/formUpdate"))
);
const ContactUs = lazy(() => lazyRetry(() => import("./components/contactus")));

const Bestsellerlist = lazy(() =>
  lazyRetry(() => import("./Bestsellerlist/Bestsellerlist"))
);
const Loreal = lazy(() => lazyRetry(() => import("./components/lorealbrand")));
const Lorealallproducts = lazy(() =>
  lazyRetry(() => import("./lorealAllproducts"))
);
const Btxhaircare = lazy(() =>
  lazyRetry(() => import("./Btxhaircare/Btxhaircare"))
);
const Spring = lazy(() => lazyRetry(() => import("./components/springh2o")));

const SingleOrder = lazy(() => lazyRetry(() => import("./components/orders/SingleOrder")));
const SearchResult = lazy(() =>
  lazyRetry(() => import("./components/searchResult"))
);
const SuccessOrder = lazy(() => lazyRetry(() => import("./SuccessOrder")));
const Unlisting = lazy(() =>
  lazyRetry(() => import("./components/unlisiting"))
);
const Shipping = lazy(() =>
  lazyRetry(() => import("./commonComponent/addressModal/Shipping"))
);
const ProductDiscription = lazy(() =>
  lazyRetry(() => import("./Cartdescription"))
);

const TRACKING_ID = "G-LHBQBP8CYF"; // Replace with your tracking ID

ReactGA.initialize(TRACKING_ID);

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);


  useEffect(() => {
    return () => {
      toast.dismiss(); // Clear all toasts when the component unmounts
    };
  }, []);
  useEffect(() => {
    toast.dismiss();
  }, [pathname]);
  const origin = console.error;
  console.error = (error) => {
    if (/Loading chunk [\d]+ failed/.test(error.message)) {
      window.location.reload();
    } else {
      origin(error);
    }
  };
  return (
    <div className="relative  roboto-regular">
      <ScrollToTop />
      <AuthContextProvider>
        <CartProvider>
          <Toaster />
          <div className="fixed top-0 z-10 w-full">
            {(pathname !== "/privacypolicy" ||
              pathname !== "/successorder") && <Navbar />}
          </div>

          <Suspense fallback={<OverlayLoader />}>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/search" element={<SearchResult />} />
              <Route path="/skinco" element={<Skinco />} />
              <Route path="/skinco/*" element={<Skinco />} />
              <Route path="/spring" element={<Spring />} />
              <Route path="/spring/*" element={<Spring />} />
              <Route path="/haircare" element={<Cartdescription />} />
              <Route path="/haircare/*" element={<Cartdescription />} />
              {/* <Route path="/best-sellers" element={<BestSeller/>} /> */}
              <Route path="/hair-care" element={<Haircareallproducts />} />
              <Route path="/skin-care" element={<Skincareallproducts />} />
              <Route path="/best-sellers" element={<Bestsellerlist />} />
              <Route
                path="/best-sellers-combos"
                element={<Skincareallproducts />}
              />
              <Route path="/newlaunches" element={<Skincareallproducts />} />
              {/* <Route path="/cartdescription/:productname/:id" element={<Cartdescription/>} /> */}
              <Route
                path="/products/:shortUrl"
                element={<ProductDiscription />}
              />
              <Route path="/training/:id" element={<Training />} />
              <Route path="/argatin" element={<Argatin />} />
              <Route path="/argatin/*" element={<Argatin />} />
              <Route path="/signUp" element={<SignUp />} />
              <Route path="/resetPassword" element={<ResetPassword />} />
              <Route path="/menicure" element={<Menicure />} />
              <Route path="/facialKit" element={<FacialKit />} />

              <Route path="/faq" element={<Faq />} />
              <Route
                path="/privacypolicy"
                element={<PrivacyPolicyComponent />}
              />
              <Route path="/returnrefund" element={<ReturnRefund />} />
              {/* <Route path="/ordersummary" element={<Ordersummary/>} /> */}
              <Route path="/aboutUs" element={<AboutUs />} />
              <Route path="/distributer" element={<Distributer />} />
              {/*<Route path="/loginform" element={<LoginForm/>} />*/}
              <Route
                path="/productdetailform"
                element={<Productformdetails />}
              />
              <Route path="/form" element={<Editform />} />
              <Route
                path="/productupdateform"
                element={<Productupdateform />}
              />
              <Route path="/formupdate" element={<Formupdate />} />
              <Route path="/btx-hair-care" element={<Btxhaircare />} />
              <Route path="/argatin-shampoo" element={<Argatinshampoo />} />
              <Route path="/hairconditioner" element={<Hairconditioner />} />
              <Route path="/hairoil" element={<Hairoil />} />
              <Route
                path="/argatin-keratin-treatment"
                element={<Argatinkeratintreatment />}
              />
              <Route path="/product-combo" element={<Listcombo />} />
              <Route path="/contactus" element={<ContactUs />} />
              <Route
                path="/termsandcondition"
                element={<TermsAndCondition />}
              />
              <Route path="/loreal" element={<Loreal />} />
              <Route path="/loreal/*" element={<Loreal />} />
              <Route
                path="/lorealallproducts"
                element={<Lorealallproducts />}
              />
              <Route element={<ProtectedRoute />}>
                <Route path="/myprofile" element={<Profile />} />
                {/* <Route path="/Profile" element={<Profile />} /> */}

                <Route path="/buy" element={<Shipping />} />
                <Route path="/orders/:id" element={<SingleOrder />} />
                <Route path="/unlisting" element={<Unlisting />} />
                <Route path="/successorder" element={<SuccessOrder />} />
              </Route>
              <Route path="*" element={<Home />} />
            </Routes>
          </Suspense>

          {/* <Footer /> */}
          {/* {(pathname !== "/PrivacyPolicy" || pathname !== "/successorder")  && <Footer />} */}
          {pathname !== "/successorder" &&
            <NewFooter />}
        </CartProvider>
      </AuthContextProvider>
    </div>
  );
}

export default App;

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { setShowLoginModal } = useContext(AuthContext);


  const isAuthenticated = useSelector((state) => state?.authReducer?.userData?.token);

  if (isAuthenticated) {
    return <Outlet />
  } else {
    setShowLoginModal(true)
    return <Navigate to="/" />
  }
};
