import Modal from "../modal/Modal";
import { IoIosArrowDown, IoIosCloseCircle, IoMdClose } from "react-icons/io";
import "./LoginModal.css";
import { BiSolidOffer } from "react-icons/bi";
import { MdOutlineShoppingCart } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { BsCartX } from "react-icons/bs";
import { useContext, useEffect, useState } from "react";
import { FaCheck, FaCreditCard, FaGreaterThan, FaTruckMoving } from "react-icons/fa";

import { FaLongArrowAltRight } from "react-icons/fa";
import { getApiCall, postApiData } from "../../utils/services";
import NewInput from "../customInputs/NewInput";
import toast from "react-hot-toast";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { successCartEmpty } from "../../Redux/actions";
import { CartContext } from "../../context/CartContext";
import { IoClose } from "react-icons/io5";

const formatDate = (date) => {

    const dateSplit = date?.split("-");
    if (dateSplit?.length < 2) {
        return date;
    }
    const monthNames = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const monthShort = monthNames[parseInt(dateSplit[1], 10) - 1];

    return `${dateSplit[2]} ${monthShort}, ${dateSplit[0]}`;

}
const Checkout = ({ show, setShow, }) => {
    return (
        <>
            <Modal show={show} setShow={setShow}>
                <ChildComponent />
            </Modal>
        </>
    );
};

const ChildComponent = ({ show, closeModal, modalRef }) => {
    const steps = ["Mobile", "Address", "Pay"];
    const [currentStep, setCurrentStep] = useState(0);
    const [currentScreen, setCurrentScreen] = useState("checkout")
    const [showOrderSummary, setShowOrderSummary] = useState(true);
    const [coupons, setCoupons] = useState([]);
    const [couponDetails, setCouponDetails] = useState({
        applied: false,
        couponCode: "",
        discount: 0,
        type: ""
    })

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const guestUserCartDetails = useSelector((state) => state.addProduct.cart);
    const [editAddress, setEditAddress] = useState(false);
    const [ selectaddress, setSelectaddress] = useState(null);
    const { setIsCartOpen } = useContext(CartContext);
    const phone = useSelector((store) => store?.authReducer?.userData?.phoneNumber);

    const [addressDetails, setAddressDetails] = useState({
        address: "",
        address2: "",
        city: "",
        pincode: "",
        state: "",
        phoneNumber: (!editAddress && phone) ? phone : "",
        name: "",
        email: "",
    });

    const handleApplyCoupon = (coupon) => {

        const data = {
            couponDiscount: Math.floor(coupon?.type === "flat" ? coupon?.discount : Math.min((coupon?.discount / 100) * total, coupon?.maxDiscount)),
            couponCode: coupon?.couponCode,
            products: guestUserCartDetails.map(({ _id, quantity }) => ({
                _id,
                quantity,
            })),
        }

        if (!data?.products?.length > 0) {
            return toast.error("Please add items to cart")

        }
        if (!coupon) {
            return toast.error("Please enter coupon code")
        }
        postApiData("user/applyCoupon", data, (response) => {
            if (response) {
                toast.success("Coupon Applied Successfully")

                setCurrentScreen("checkout")

                const orderAmount = Math.floor(response?.type === "flat" ? response?.discount : Math.min((response?.discount / 100) * total, response?.maxDiscount));
                setCouponDetails({
                    applied: true,
                    couponCode: response?.couponCode,
                    discount: orderAmount,
                    type: response?.type
                })
            }
        }, (err) => {
            toast.error(err?.response?.data?.message)
        }, true)

    }

    const total = guestUserCartDetails?.reduce(
        (acc, item) => acc + item.quantity * item.price,
        0
    );
    const orderTotal = total > 500 ? (total - couponDetails?.discount) || 0 : ((total - couponDetails?.discount) + 50) | 0;
    const cart = guestUserCartDetails.map(({ _id, quantity }) => ({
        _id,
        quantity,
    }));
    const handleSummaryClick = () => {
        setShowOrderSummary((prev) => !prev)
    }
    const handleScreen = (screen) => {
        setCurrentScreen(screen)
    }


    const captureOrderDetails = (razorpayid, orderId, totalAmount, selectedOption) => {
        const data = {
            orderId: orderId,
            // orderId: orderData._doc._id,
            orderAmount: totalAmount,
            paymentMethod: selectedOption === "online" ? 2 : 1,
            // selectedOption,
            razorPayId: razorpayid,
        };
        postApiData("user/captureProductOrder", data, (response) => {
            if (response) {
                toast.success("order has been placed");
            }

        }, (err) => {
            toast.error(err?.response?.data?.message)
        }, true);
    };

    const onClickPayment = (selectedOption) => {

        const data = {
            phoneNumber: selectaddress?.phoneNumber,
            name: selectaddress?.name,
            emailId: selectaddress?.email,
            paymentMethod: selectedOption === "online" ? 2 : 1,
            addresses: {
                address: selectaddress?.address,
                address2: selectaddress?.address2,
                city: selectaddress?.city,
                state: selectaddress?.state,
                pincode: +selectaddress?.pincode,
                name: selectaddress?.name,
                phoneNumber: selectaddress?.phoneNumber,
            },
            orderAmount: orderTotal,
            couponApplied: couponDetails?.applied,
            coupon: couponDetails?.couponCode,
            couponDiscount: couponDetails?.discount,

            products: cart,
        };
        // if(selectaddress==="" ){
        postApiData(
            "user/createProductOrder",
            data,
            (response) => {
                if (response) {
                    setLoading(true)

                    if (selectedOption === "cash") {
                        // setCash(true);
                        toast.success("You Order has been Successfully Placed!!");
                        setLoading(false)
                        setIsCartOpen(false);
                        dispatch(successCartEmpty());
                        closeModal();
                        navigate("/successorder");
                    }
                    else if (selectedOption === "online") {

                        try {

                            const options = {
                                // key: "rzp_test_hdlZcgaBXfBRBr",
                                // key: "rzp_test_Ira5eelRPeWjZn",
                                // key: "rzp_live_ZmjmD6vtJZ9giq",
                                key: "rzp_live_gPOHKfuDqbcZFw",
                                amount: response.amount, // Amount in paise
                                currency: "INR",
                                name: "SalonKart",
                                description: "Order",
                                order_id: response.id,
                                handler: async (responses) => {
                                    try {

                                        if (responses) {


                                            // captureOrderDetails(response?.razorpay_payment_id, response?.razorpay_order_id);
                                            captureOrderDetails(
                                                responses?.razorpay_payment_id,
                                                response?._doc?._id,
                                                response?._doc?.totalPayableAmount,
                                                selectedOption
                                            );

                                            toast.success("Your order has been placed");
                                            setSelectaddress(null)
                                            setIsCartOpen(false);

                                            closeModal();
                                            dispatch(successCartEmpty());
                                            navigate("/successorder");
                                        } else {

                                        }
                                    } catch (error) {
                                    }



                                },
                                prefill: {
                                    name: selectaddress?.name,
                                    email: selectaddress?.email,
                                    contact: selectaddress?.phoneNumber,
                                },
                                notes: {
                                    address: "Note Address",
                                },
                                theme: {
                                    color: "#61dafb",
                                },
                            };

                            const razorpay = new window.Razorpay(options);
                            setLoading(true);
                            try {
                                razorpay.open();
                                // Assume modal is opened after a small delay
                                setTimeout(() => {
                                    setLoading(false); // Stop loader after the modal opens
                                }, 500); // Adjust delay as needed
                            } catch (error) {
                                setLoading(false); // Stop loader on error
                                toast.error("Failed to open payment gateway. Please try again.");
                            }

                            razorpay.on("payment.failed", function (response) {
                                setLoading(false); // Stop the loading indicator

                                // Display a user-friendly error message
                                toast.error("Payment failed");

                                // Optional: Log detailed error for debugging purposes
                            });
                            razorpay.on("close", () => {
                                setLoading(false);
                                toast.error("Payment was canceled by the user.");
                            });
                        } catch (error) {
                            setLoading(false);

                        }

                    }
                }



            },
            (error) => {
                toast.error(error?.response?.data?.message);
                setLoading(false)

                //   alert("order error: " + error.message); // Assuming error.message contains the actual error message
            }
            ,
            true
        );
        // }else{
        //   toast.error("Enter all required details!")
        // }
    };
    const onClickCod = () => {
        onClickPayment("cash")
    }
    const onClickOnline = () => {
        onClickPayment("online")
    }


    const isEmailValid = () => {
        const emailRgx =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        return emailRgx.test(selectaddress?.email)

    }
    const isAddressFieldValid = () => {
        // Check if `selectaddress` is null or has any empty values
        const isSomeAddressEmpty = !selectaddress ||
            Object.values(selectaddress).some(val => !val || val.toString().trim() === "");

        // Check if `userDetails` has any empty values

        // Return false if any field is empty; otherwise, return true
        return !isSomeAddressEmpty;
    };

    const handleStepChange = () => {
        if (currentStep < steps.length - 1) {

            if (currentStep === 1) {
                if (!isEmailValid()) {
                    toast.dismiss()
                    toast.error("Please select address")
                    return

                }

                if (!isAddressFieldValid()) {
                    toast.dismiss()
                    toast.error("Please select address")
                    return
                }
            }
            setCurrentStep((prev) => prev + 1)
            
        }
    }
    const handleStepClick = (index) => {



        if (index === 1) {
            if (!isEmailValid()) {

                return

            }

            if (!isAddressFieldValid()) {

                return
            }
        }
        if (index === 2) {
            if (!isAddressFieldValid()) {
                return
            }

        }
        setCurrentStep(index)


    }
    const handleEditAddress = () => {


        setEditAddress(true);
        setAddressDetails(selectaddress)
        setCurrentStep(1)



    }
    const handleRemoveCoupon = () => {

        setCouponDetails({
            applied: false,
            couponCode: "",
            discount: 0,
            type: ""
        })
    }

    useEffect(() => {
        getApiCall("user/getCouponsList", (response) => {
            if (response) {
                setCoupons(response)
            }
        },
            () => {

            }

        )

    }, [])


    return (
        <>
            <div className="relative fon-Inter h-full w-full flex items-center justify-center font-[inherit]  bg-black/30 ">
                {/* Modal content */}
                <div
                    ref={modalRef}
                    className="relative overflow-hidden h-full md:h-[90%] my-auto w-full md:w-[35%] xl:w-[25%] md:min-w-[400px] mx-auto bg-gray-100 rounded-[1rem] border border-white/40 "
                >
                    {/* Modal header */}
                    {currentScreen === "checkout" ?
                        <>
                            <div className="p-[15px] relative bg-white  flex items-center justify-center">


                                <div className="flex items-center justify-center space-x-4">
                                    {steps.map((step, index) => (
                                        <div key={index} className="flex items-center space-x-2">
                                            {/* Step label */}
                                            <span
                                                onClick={() => handleStepClick(index)}
                                                className={`text-[12px] cursor-pointer flex items-center gap-1  ${(currentStep === index)
                                                    ? "text-black font-bold"

                                                    : currentStep > index ? "text-green-600" : "text-gray-400"
                                                    }`}
                                            >
                                                {currentStep > index && <FaCheck />
                                                } {step}
                                            </span>
                                            {/* Dots */}
                                            {index < steps.length - 1 && (
                                                <span
                                                    className={`w-3 h-[2px] border  border-dashed ${currentStep > index ? "border-green-600" : "border-gray-300"
                                                        }`}
                                                />
                                            )}
                                        </div>
                                    ))}
                                </div>
                                <button
                                    onClick={closeModal}
                                    className="hover:bg-white absolute right-[10px] text-[30px] rounded-md hover:text-black text-gray-200 p-1"
                                >
                                    <IoIosCloseCircle handleScreen={handleScreen} />
                                </button>
                            </div>
                            <div className="bg-light-green p-[5px] flex items-center justify-center gap-2  text-black text-[12px] text-center">
                                <BiSolidOffer className="text-green-500 text-md" /> <span>Extra <strong>Discount</strong> Available at Payment Step</span>
                            </div>
                            <div className={` ${currentStep === 2 ? "h-[calc(100%-100px)]" : "h-[calc(100%-164px)]"}  overflow-y-auto`}>
                                <div className="m-[10px]">
                                    <div onClick={handleSummaryClick} className=" cursor-pointer transition-all ease-in duration-100 delay-75 bg-white rounded-[10px]">
                                        <div className=" flex items-center justify-between  p-[10px] ">
                                            <div className="flex items-center gap-2">
                                                <div className="text-[20px]">
                                                    <MdOutlineShoppingCart />

                                                </div>
                                                <div className="text-[14px] flex items-center gap-3 justify-center font-normal">
                                                    <span> Order Summary</span>
                                                    <span className="text-[13px]"><IoIosArrowDown />


                                                    </span>

                                                </div>
                                            </div>
                                            <div className="text-[16px] font-[600]">
                                                ₹{orderTotal ? orderTotal?.toFixed(2) : 0}


                                            </div>
                                        </div>
                                        {showOrderSummary && <div>
                                            <div className="">
                                                {guestUserCartDetails.length > 0 ? (
                                                    guestUserCartDetails?.map((item, index) => {

                                                        return (
                                                            <div className="p-[10px]   pb-[10px]   gap-3 flex items-center justify-between ">
                                                                <div className="flex items-center justify-center gap-3">
                                                                    <div>
                                                                        <img
                                                                            loading="lazy"
                                                                            src={item?.images?.length > 0 ? item?.images[0] : ""}
                                                                            className=" h-[60px] border rounded-tl-[12px] aspect-square max-w-max"
                                                                            alt="product"
                                                                        />
                                                                    </div>
                                                                    <div className=" mb-auto">
                                                                        <span className="line-clamp-1 overflow-hidden  text-[11px] font-normal   ">
                                                                            {item?.name}
                                                                        </span>
                                                                        <div className="text-[11px] font-normal flex items-center justify-center w-fit gap-1" >
                                                                            <span>Price:</span>
                                                                            <span>{item?.price}</span>
                                                                        </div>
                                                                        <div className="text-[11px] font-normal flex items-center justify-center w-fit gap-1" >
                                                                            <span>Quantity:</span>
                                                                            <span>{item?.quantity}</span>
                                                                        </div>






                                                                    </div>
                                                                </div>


                                                                {/* <MdClose
                                                    style={{ marginRight: "15px", marginTop: "10px" }}
                                                    onClick={() => removeitem(item)}
                                                  /> */}
                                                            </div>
                                                        );
                                                    })
                                                ) : (
                                                    <div className="empty-cart font-Inter">
                                                        <BsCartX />
                                                        <span>No products in the cart</span>

                                                    </div>
                                                )}
                                            </div>
                                            <div className="p-[10px]">
                                                <div className="pb-[10px] border-b">
                                                    <div className="text-[14px]  pb-1 font-normal flex items-center justify-between">
                                                        <span>Subtotal</span>
                                                        <span> ₹{total ? total?.toFixed(2) : total}</span>
                                                    </div>
                                                    <div className="text-[14px] pb-1  font-normal flex items-center justify-between">
                                                        <span>Discount</span>
                                                        <span className="text-green-600 font-medium">{`- ₹${couponDetails?.discount}`}</span>
                                                    </div>
                                                    <div className="text-[14px]   font-normal flex items-center justify-between">
                                                        <span>Shipping</span>
                                                        <span>{total < 500 ? "₹" + 50 : 'Free'}</span>
                                                    </div>


                                                </div>
                                                <div className="text-[15px] py-[10px] font-[700]  flex items-center justify-between">
                                                    <span>To Pay</span>
                                                    <span>  ₹{orderTotal > 0 ? orderTotal?.toFixed(2) : 0}</span>
                                                </div>
                                            </div>
                                        </div>}
                                    </div>
                                    {coupons?.length > 0 && <div className="rounded-[10px] my-5 w-full bg-white  py-[5px] shadow border">
                                        {couponDetails?.applied &&
                                            <div className=" mx-[10px] pt-[5px] border-b border-gray-400 border-dashed pb-1 ">
                                                <div className="rounded-[10px]  px-[10px] py-[6px] bg-gray-100 my-[5px] flex items-center justify-between">

                                                    <div className="text-[12px] flex font-[500]  items-center justify-center gap-2">


                                                        <BiSolidOffer className="text-green-600 text-[20px]" />
                                                        <span className="flex items-center gap-1"><span className="text-[13px] font-bold">{couponDetails?.couponCode || "FAV99"}</span> applied</span>
                                                        <span className="bg-light-green text-[11px] text-green-700 px-[14px] py-[1px] rounded-[10px]"> Saved ₹{couponDetails?.discount}</span>

                                                    </div>
                                                    <button onClick={handleRemoveCoupon}>
                                                        <IoMdClose />
                                                    </button>



                                                </div>
                                            </div>
                                        }

                                        <div className="px-[10px] py-[5px] flex items-center justify-between">
                                            <div className="text-[11px] font-[500] flex items-center justify-center gap-2"> <span>{coupons?.length} Coupons Available</span></div>
                                            <div><button onClick={() => handleScreen("coupon")} className="text-[12px] font-[600] text-[#004b8d] flex items-center justify-center gap-1 "><span>View Coupons</span> <FaGreaterThan className="text-[13px]" />
                                            </button></div>

                                        </div>
                                    </div>}
                                    {

                                        currentStep === 1 ?
                                            <Address setEditAddress={setEditAddress} addressDetails={addressDetails} setAddressDetails={setAddressDetails} selectaddress={selectaddress} setSelectaddress={setSelectaddress} editAddress={editAddress} />
                                            : currentStep === 2 && <Payment loading={loading} onClickCod={onClickCod} onClickOnline={onClickOnline} selectaddress={selectaddress} handleEditAddress={handleEditAddress} orderTotal={orderTotal} />}


                                </div>
                            </div>
                            <div className="bg-white rounded-[10px] p-[15px]">

                                {(currentStep === 2) ? <div></div> : <button onClick={handleStepChange} className="bg-black text-white font-[500] text-[12px] flex items-center justify-center gap-3 rounded-[5px] p-[10px] w-full my-[10px]" >
                                    <span>  Continue</span>
                                    <span>  <FaLongArrowAltRight />
                                    </span>

                                </button>}
                            </div></> : <Coupons applyCoupon={handleApplyCoupon} couponDetails={couponDetails} coupons={coupons} handleScreen={handleScreen} orderTotal={total} />}


                    {/* Modal body */}
                </div>
            </div>
        </>
    );
};

const Payment = ({ orderTotal, handleEditAddress, selectaddress, onClickCod, onClickOnline, loading }) => {
    return (
        <>
            <div className=" font-Inter    ">

                <div className="text-[13px] flex items-center gap-1 my-3 font-normal">
                    Hey! Welcome back <span>+91 {selectaddress?.phoneNumber}</span><span><button onClick={() => handleEditAddress()} className="text-[#004b8d] bg-[#e6edf4] text-[11px] rounded-[10px] px-[10px] py-[3px]">Edit</button></span>

                </div>
                <div className="border p-[15px] border-[#d0d5da] my-5 bg-white rounded-[10px]">
                    <div className="flex items-center justify-between">
                        <span className="text-[14px] font-bold">Deliver To</span>
                        <button onClick={() => handleEditAddress()} className="text-[#004b8d] bg-[#e6edf4] text-[11px] rounded-[10px] px-[10px] py-[3px]">Edit</button>
                    </div>
                    <div className="mt-2">
                        <div className="text-[14px] ">{selectaddress?.name || ""}</div>
                        <div className="text-[12px] text-gray-700">{selectaddress?.address},{selectaddress?.address2} <span>{selectaddress?.pincode || ""}</span></div>
                        <div className="text-[#004b8d] my-1 bg-[#e6edf4] text-[11px] rounded-[10px] w-fit px-[10px] py-[3px]">{selectaddress?.email}</div>
                    </div>

                </div>

                <div>
                    <h2 className="text-[13px]">Payment Options</h2>
                    <div className="py-2">
                        {!loading ? <button onClick={onClickOnline} className="bg-black text-white font-[500] text-[13px] flex items-center justify-between gap-3 rounded-[5px] px-[15px] py-[19px] w-full my-[10px]" >
                            <span className="flex items-center justify-start  gap-2">
                                <span><FaCreditCard className="text-[16px]" />
                                </span>
                                <span>Upi/Card/Netbanking</span>
                            </span>

                            <span className=" font-[500] flex items-center gap-1 text-white"> ₹{orderTotal ? orderTotal?.toFixed(2) : orderTotal}<FaGreaterThan className="text-[14px]" /></span>
                        </button> : <button className="bg-black text-white font-[500] text-[13px] flex items-center justify-center gap-3 rounded-[5px] px-[15px] py-[19px] w-full my-[10px]" >
                            <div className="flex items-center justify-center flex-row gap-2">
                                <div className="w-3 h-3 rounded-full bg-white animate-bounce [animation-delay:.7s]" />
                                <div className="w-3 h-3 rounded-full bg-white animate-bounce [animation-delay:.3s]" />
                                <div className="w-3 h-3 rounded-full bg-white animate-bounce [animation-delay:.7s]" />
                            </div>    </button>}
                        <button disabled={loading} onClick={onClickCod} className="bg-black text-white font-[500] text-[13px] flex items-center justify-between gap-3 rounded-[5px] px-[15px] py-[19px] w-full my-[10px]" >
                            <span className="flex items-center justify-start gap-2">

                                <span><FaTruckMoving className="text-[16px]" />


                                </span>
                                <span> Cash On Delivery</span>
                            </span>
                            <span className=" font-[500] flex items-center gap-1 text-white"> ₹{orderTotal ? orderTotal?.toFixed(2) : orderTotal}<FaGreaterThan className="text-[14px]" /></span>
                        </button>
                    </div>
                </div>


            </div>
        </>
    )
}

const Address = ({
    editAddress,
    setEditAddress,
    selectaddress,
    setSelectaddress,

    addressDetails,
    setAddressDetails
}) => {
    const [filledAddress, setFilledAddress] = useState([]);
    const [addNewAddress, setAddNewAddress] = useState(false);
    const phone = useSelector((store) => store?.authReducer?.userData?.phoneNumber);


    const handleRadioChange = (event) => {
        const selectedAddressId = event.target.value;
        const data = filledAddress.find((elm) => elm._id === selectedAddressId);
        setSelectaddress(data);
        // You can now use selectedAddressId to set the selected address in your state or perform other actions
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setAddressDetails((prev) => ({
            ...prev,
            [name]: name === "pincode" ? value.replace(/\D/g, "").slice(0, 6) : value
        }));

    }

    useEffect(() => {
        getApiCall("user/getUserAddress", (resp) => {
            setFilledAddress(resp.addresses);
        });
    }, []);


    const addressFields = [
        {
            name: "pincode",
            label: "Enter Pincode",
        },
        {
            name: "city",
            label: "Enter City",
        },
        {
            name: "state",
            label: "Enter State",
        },
        {
            name: "address",
            label: "Enter Address",
        },
        {
            name: "address2",
            label: "Enter Landmark",
        },
        {
            name: "name",
            label: "Enter Name",

        },
        {
            name: "email",
            label: "Enter Email",
        },
        {
            name: "phoneNumber",
            label: "Enter Phone Number",

        },

    ];
    const isMobileValid = () => {
        const mobileRegex = /^[6-9][0-9]{9}$/;
        return mobileRegex.test(addressDetails?.phoneNumber)

    }
    const isEmailValid = () => {
        const emailRgx =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;


        return emailRgx.test(addressDetails?.email)

    }
    const addNewAddressClick = (e) => {
        e.preventDefault();
        const isSomeAddressEmpty = Object.values(addressDetails).some((value) => !value || value?.toString()?.trim() === "");
        if (isSomeAddressEmpty) {
            toast.error("Fill All Fields")
            return

        }
        if (!isMobileValid()) {
            toast.error("Invalid Phone Number")
            return

        }
        if (!isEmailValid()) {
            toast.error("Invalid Email")
            return

        }

        const { address, address2, city, pincode, state, name, email, phoneNumber } = addressDetails;

        const combineAddress = {
            ...(editAddress && { _id: addressDetails?._id }),
            address,
            address2,
            city,
            pincode: +pincode,
            state,
            name,
            email,
            phoneNumber,
            isActive: true,
        };
        if (editAddress) {


            postApiData(
                "user/editAddress",
                combineAddress,
                (response) => {
                    toast.success("address edited SucessFully");
                    setEditAddress(false)

                    setFilledAddress(prev => prev.map((elm) => elm._id === combineAddress?._id ? combineAddress : elm))
                    setSelectaddress(combineAddress)

                },
                () => { }
            );
        }

        else {

            postApiData(
                "user/addAddressToUser",
                combineAddress,
                (response) => {

                    toast.success("address Added SucessFully");
                    setAddNewAddress(false)
                    setAddressDetails({
                        address: "",
                        address2: "",
                        city: "",
                        pincode: "",
                        state: "",
                        name: "",
                        email: "",
                        phoneNumber: (!editAddress&& phone)?phone:""
                    
                    })
                    setFilledAddress(response?.addresses)


                },
                () => { }
            );


        }

        // setAddNewAddress(false)

    };

    useEffect(() => {
        const fetchData = async () => {
            const pins = addressDetails?.pincode?.toString();
            if (pins?.length === 6) {
                try {
                    const response = await axios.get(
                        `https://api.postalpincode.in/pincode/${pins}`
                    );
                    if (response?.data?.length > 0) {

                        if (response?.data[0]?.Status === "Success") {

                            setAddressDetails((prev) => {

                                const st = response?.data[0]?.PostOffice[0]?.State
                                const ct = response?.data[0]?.PostOffice[0]?.Block

                                return {


                                    ...prev,
                                    state: st === "NA" ? "" : st,
                                    city: ct === "NA" ? "" : ct
                                }
                            })
                        }
                        else {
                            setAddressDetails((prev) => {
                                return {
                                    ...prev,
                                    state: "",
                                    city: ""
                                }
                            })

                        }
                    }

                } catch (error) { }
            }
        };

        fetchData();

        // Return a cleanup function (if needed)
        return () => {
            // Cleanup code here (if needed)
        };
    }, [addressDetails?.pincode]);

    const handleNewAddressClick = () => {
        setAddNewAddress(true)
        setEditAddress(false)
    }
    const handleClose = () => {
        if (addNewAddress){ 
            setAddNewAddress(false)
           setAddressDetails({
            address: "",
            address2: "",
            city: "",
            pincode: "",
            state: "",
            name: "",
            email: "",
            phoneNumber: (!editAddress&& phone)?phone:""
           })
        }
        if (editAddress) setEditAddress(false)

    }
    return (
        <>
            <div className="my-5 ">

                <div className="border-b border-gray-300 flex items-center justify-between">
                    <h2 className="text-[15px] font-[500] text-black pb-1  ">{addNewAddress ? "Add New Address" : editAddress ? "Edit Address" : "Select Delivery Address"}</h2>
                    {!addNewAddress ? 
                    <div><button onClick={handleNewAddressClick} type="button" className="text-[12px] flex items-center gap-1 text-[#004b8d] pb-1 font-[500] "><span className="text-[14px]">+</span> Add New Address</button></div>
                        : <div><button onClick={handleClose} type="button" className="text-xl flex items-center gap-1 text-[#004b8d] pb-1 font-[500] "><span className=""><IoClose />
                        </span> </button></div>


                    }                </div>
                {/* Map over the address objects array to create radio inputs */}
                {(!addNewAddress && !editAddress) &&
                    <div className="my-5 max-h-[45vh] overflow-y-auto overflow-x-hidden">
                        {filledAddress?.length > 0 ?
                            filledAddress.map((option) => (
                                <label
                                    htmlFor={option._id}
                                    key={option._id}
                                    className="mb-2 flex cursor-pointer hover:bg-gray-50 border border-gray-300 p-2 shadow-sm rounded-sm"
                                >
                                    <input
                                        type="radio"
                                        id={option._id}
                                        name="selectedAddress"
                                        value={option._id}
                                        checked={option?._id === selectaddress?._id}
                                        onChange={handleRadioChange} // Handle the selected address
                                        className="mr-2 cursor-pointer"
                                    />
                                    <div className="text-sm cursor-pointer">
                                        <div>
                                            <h2 className="font-medium text-[13px]"> {option?.name}</h2>
                                            <h2 className="font-medium text-[13px]"> {option?.address}</h2>
                                            <h2 className="text-[12px]"> {option?.address2}</h2>
                                            <h2 className="text-[12px]"> {option?.email}</h2>
                                            <h2 className="text-[12px]"> {option?.phoneNumber}</h2>
                                            <h2 className="text-[12px]">
                                                {" "}
                                                {option.city} {option.state}, {option.pincode}
                                            </h2>
                                        </div>
                                    </div>
                                </label>
                            )) :
                            <div className="bg-white p-6">
                                <div className=" flex items-center justify-center">

                                    <h2 className="text-[14px] font-[500] text-gray-600">No Address Found</h2>

                                </div>

                            </div>}
                    </div>}
            </div>
            {(addNewAddress || editAddress) && <form onSubmit={addNewAddressClick} className="flex flex-col gap-2">
                {addressFields?.map((elm, index) => {
                    return (
                        <NewInput name={elm?.name} label={elm?.label} onChange={handleChange} value={addressDetails[elm?.name]} />
                    )
                })
                }
                <button type="submit" className="bg-black text-white font-[500] text-[12px] flex items-center justify-center gap-3 rounded-[5px] p-[10px] w-full my-[10px]" >
                    <span> {addNewAddress ? "Add" : "Edit"} Address</span>
                    <span>  <FaLongArrowAltRight />
                    </span>

                </button>
            </form>}



        </>
    )
}

const Coupons = ({ handleScreen, orderTotal, coupons, applyCoupon, couponDetails }) => {
    const [availableCoupon, setAvailableCoupons] = useState([])

    const [showMore, setShowMore] = useState([...Array(availableCoupon?.length).fill(false)])

    const handleShowMore = (index) => {
        let updated = [...showMore];
        updated[index] = !updated[index]
        setShowMore(updated)
    }




    useEffect(() => {
        if (coupons?.length > 0) {


            // const availCoupon = [];
            // coupons?.forEach((elm) => {

            //         availCoupon.push(elm)

            // })

            setAvailableCoupons(coupons);
        }
    }, [coupons])

    return (
        <>
            <div className="p-[15px] font-Inter    flex items-center justify-between">

                <div className="text-[0.9rem] font-bold">
                    Offers & Benefits
                </div>

                <button
                    onClick={() => handleScreen("checkout")}
                    className="= text-[22px] rounded-md text-black "
                >
                    <IoMdClose />
                </button>
            </div>
            <div className="h-[calc(100%-75px)] overflow-y-auto">
                {availableCoupon?.length > 0 && <div className="p-[15px]">
                    <h2 className="text-[14px] leading-[24px] font-[500] text-[#050038]">Available Offers

                    </h2>
                    <div className="">
                        {availableCoupon?.map((coupon, index) => {
                            const desc = `${coupon?.type === "flat" ? "Flat " : ""}${coupon?.discount}${coupon?.type === "percentage" ? "%" : ""} Off On Order Above ₹${coupon?.minOrder}`

                            const terms = `Max Discount Upto ₹${coupon?.maxDiscount}`
                            const requiredAmount = coupon?.minOrder - orderTotal
                            return (
                                <div className="py-[15px] border-b pb-5 border-dashed border-[#929ca8]">
                                    <div className="flex items-center justify-between">
                                        <div className="flex items-center gap-2 mb-3">
                                            <span className="bg-gray-200 text-black h-[35px] w-[35px] text-[0.98rem]  rounded-[100%] flex items-center justify-center"><BiSolidOffer />
                                            </span><span className="border text-black border-dashed border-[#929ca8] px-[0.5rem] py-[0.20rem] text-[0.85rem] rounded-[4px] flex items-center uppercase justify-center">
                                                {coupon?.couponCode}
                                            </span>
                                        </div>
                                        {requiredAmount < 0 && <div>
                                            {couponDetails?.couponCode === coupon?.couponCode ?
                                                <span className="text-[14px] text-green-600 font-bold">APPLIED</span> :

                                                <button onClick={() => applyCoupon(coupon)} className="text-[14px] font-bold">APPLY</button>

                                            }

                                        </div>}
                                    </div>
                                    {requiredAmount > 0 && <div className="text-[11px] my-1  font-medium text-red-600">{`Add Items Worth ₹${requiredAmount} More To Avail`}</div>}
                                    <div className="text-[11px]  font-medium text-gray-500">{desc}</div>
                                    {coupon?.type === "percentage" && <> {showMore[index] &&
                                        <div className="my-2 p-[10px] rounded-[4px] bg-neutral-200">
                                            <ol>
                                                <li className="text-[11px] font-bold text-gray-600">{terms}</li>
                                            </ol>
                                        </div>}

                                        <button onClick={() => handleShowMore(index)} className="text-[12px] underline font-bold text-gray-500">{!showMore[index] ? "Show More" : "Show Less"}</button>
                                    </>}
                                </div>
                            )
                        })}
                    </div>
                </div>}

            </div>
        </>
    )


}

export default Checkout;
