import { useContext, useEffect, useState } from 'react';
import logo from "./img/logo.png";
import { Link, useNavigate } from 'react-router-dom';
import { MdKeyboardArrowDown } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import Cart from '../commonComponent/cardDetails';
import { postApiData } from '../utils/services';
import { CartContext } from '../context/CartContext';
import TypingSearchInput from '../components/customInputs/TypingInput';
const SmallNavbar = ({ setShowModal,onClickPayment }) => {
  const { isCartOpen, setIsCartOpen, cartTotal, setCartTotal, searchResults, setSearchResults } = useContext(CartContext);
  const cartLengthData = useSelector((store) => store.addProduct.cart)
  const [hamburgermenu, sethamburgermenu] = useState(false);
  const [branddetails, setbrandDetails] = useState(false);
  const [test, setTest] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const authToken = useSelector((state) => state?.authReducer?.userData?.token);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  useEffect(() => {
    const data = {
      name: searchTerm
    }
    if (searchTerm.length > 0) {
      const data = {}
      postApiData(
        `user/getSearchedProduct?name=${searchTerm}`,
        data,
        (resp) => {

          setSearchResults(resp)
        },
        (error) => {

        }
      )
    } else {
      setSearchResults([])
    }
  }, [searchTerm])

  function hamburgermenuopen() {
    sethamburgermenu(!hamburgermenu);
  }

  function handleLinkClick() {
    sethamburgermenu(false);
  }

  function brandddetails() {
    setbrandDetails(!branddetails);
  }

  
  

  const onpress = () => {
    // setIsOpen(false);
    // setShowCart(true);
    setIsCartOpen(true)
  };
  const openSmallUserModal = () => {


    if (!authToken) {
      setShowModal(true);

    } else {
      navigate("/myprofile")
    }



  }
 
  const handleSearch = (event) => {
    const value = event.target.value
    if (value === '') {
      setSearchTerm('');
      navigate('/');
    } else {
      setSearchTerm(value);
      navigate(`/search${value ? `?text=${value}` : "text=''"}`);
    }
  };

  return (
    <div className='w-full  bg-black font-Inter  flex flex-col px-3 py-4 top-0 sticky z-10'>
      <div className='flex relative justify-between items-center'>
        <button className={`site-nav__link site-nav__link--icon medium-up--hide mobile-nav-trigger ${hamburgermenu?"is-active":""}`} onClick={hamburgermenuopen}>
          <svg

            xmlns="http://www.w3.org/2000/svg"
            aria-hidden="true"
            focusable="false"
            role="presentation"
            className="icon icon-hamburger icon--line w-[27px] h-[27px] inline-block align-middle text-white"
            viewBox="0 0 64 64"
            fill='currentColor'
          >
            <path d="M7 15h51" fill="none" stroke="currentColor">
              .
            </path>
            <path d="M7 32h43" fill="none" stroke="currentColor">
              .
            </path>
            <path d="M7 49h51" fill="none" stroke="currentColor">
              .
            </path>
          </svg>
        </button>
        <Link to="/" className='absolute left-1/2 transform -translate-x-1/2'><img loading='lazy' className="w-auto mx-auto h-[22px]" src={logo} alt="logo" /></Link>
        <div className='flex justify-between items-center gap-x-3'>
          <button className='' onClick={openSmallUserModal}>
            <svg
              width={25}
              height={25}
              className='text-white w-[27px] h-[27px] inline-block align-middle'
              viewBox="0 0 27 27"
              fill="currentColor"
              xmlns="http://www.w3.org/2000/svg"
              id="svgkp"
            >
              <path
                d="M22.9129 12.935L13.7571 23.0474C13.5348 23.2929 13.1284 23.1084 13.1669 22.7794L14.0816 14.9731H10.6991C10.4034 14.9731 10.2484 14.6219 10.4478 14.4035L20.3133 3.59739C20.5589 3.32834 20.9984 3.58134 20.8891 3.92887L18.2354 12.3664H22.6607C22.9557 12.3664 23.1109 12.7163 22.9129 12.935Z"
                fill="#FEA203"
              />
              <path
                id="svgkp-path"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M16.6079 5.35819C16.4805 5.1933 16.3421 5.03582 16.1932 4.8869C15.2702 3.96387 14.0183 3.44531 12.7129 3.44531C11.4075 3.44531 10.1556 3.96387 9.2326 4.8869C8.30957 5.80993 7.79102 7.06183 7.79102 8.36719C7.79102 9.67255 8.30957 10.9244 9.2326 11.8475C9.48368 12.0986 9.75909 12.3197 10.0533 12.5086L11.0235 11.4503C10.7335 11.2914 10.4649 11.0911 10.227 10.8531C9.56766 10.1938 9.19727 9.29959 9.19727 8.36719C9.19727 7.43479 9.56766 6.54057 10.227 5.88127C10.8863 5.22196 11.7805 4.85156 12.7129 4.85156C13.6453 4.85156 14.5395 5.22196 15.1988 5.88127C15.3636 6.04604 15.5103 6.22549 15.6377 6.41654L16.6079 5.35819ZM20.6413 18.6497L19.6746 19.7132C20.1676 20.4122 20.4473 21.2264 20.4473 22.0781V23.8359C20.4473 24.2243 20.7621 24.5391 21.1504 24.5391C21.5387 24.5391 21.8535 24.2243 21.8535 23.8359V22.0781C21.8535 20.7863 21.4016 19.6103 20.6413 18.6497ZM12.3111 17.5078H10.3026C7.27113 17.5078 4.97852 19.6394 4.97852 22.0781V23.8359C4.97852 24.2243 4.66372 24.5391 4.27539 24.5391C3.88707 24.5391 3.57227 24.2243 3.57227 23.8359V22.0781C3.57227 18.6922 6.67684 16.1016 10.3026 16.1016H12.4885L12.3111 17.5078Z"
                fill="currentColor"
                stroke="currentColor"
              />
            </svg>

          </button>
          <div onClick={onpress} className='relative cursor-pointer'>
            <p className="cart-link__bubble"><span className="text-white relative">{cartLengthData?.length}</span></p>
            <button  >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-hidden="true"
                focusable="false"
                role="presentation"
                className="icon icon-cart icon--line w-[27px] h-[27px] inline-block align-middle text-white"
                fill='currentColor'
                viewBox="0 0 64 64"
                width={20}
                height={20}
              >
                <path
                  d="M14 17.44h46.79l-7.94 25.61H20.96l-9.65-35.1H3"
                  strokeMiterlimit={10}
                  fill="none"
                  stroke="currentColor"
                />
                <circle cx={27} cy={53} r={2} fill="currentColor" stroke="currentColor" />
                <circle cx={47} cy={53} r={2} fill="currentColor" stroke="currentColor" />
              </svg>
            </button>

          </div>
        </div>
      </div>
      <div className='mx-auto w-full  mt-5'>
        <TypingSearchInput
         fullWidth={true}
          searchTerm={searchTerm}
          handleSearch={handleSearch}
        />      </div>
    

      {/* {showCart && (
                 <Cart setShowCart={setShowCart} setTest={setTest} test={test} />
               )} */}
      {isCartOpen && (
        <Cart setShowCart={setIsCartOpen} isCartOpen={isCartOpen} setTest={setTest} test={test} onClickPayment={onClickPayment}
 />
      )}

      {/* {loginModal && (
                <Login modalIsOpen={loginModal} setIsOpen={setLoginModal} />
              )} */}

    
      {hamburgermenu && (
        <div
          onClick={() => sethamburgermenu(false)} // Close modal when clicking outside

          className={` fixed   top-[110px] h-full bg-black/30 left-0 right-0 w-full`}
        >
        <div 
         onClick={(e) => e.stopPropagation()} // Prevent closing when clicking inside
         className={`relative ${hamburgermenu?"slide-in-top ":""} top-0 w-[95%] mx-auto shadow border  bg-white p-9`}>
          {/* <button className="text-white"> */}
          {/* </button> */}
          <div className="text-black  text-lg ">
            <Link
              to={{
                pathname: "/best-sellers",
              }}
              className="text-[16px] font-medium pb-2 lg:text-[23px] flex justify-start item-center border-b border-white"
              onClick={handleLinkClick}
            >
              Best Seller
            </Link>
            <div className='transition-all ease-in duration-100 delay-75 pb-2'>
            <div className="flex items-center justify-between " onClick={brandddetails}>
              <label className="text-[16px] font-medium  lg:text-[23px] flex justify-start item-center border-b border-white w-full">Brands</label>
              <MdKeyboardArrowDown className="brandaicon" />
            </div>
        
              <div className={`${branddetails ? 'block' : 'hidden'}  `}>
                <h2>
                  <Link
                    to="/spring"
                    className="text-[12px] underline underline-offset-1 "
                    onClick={handleLinkClick}
                  >
                    Spring H2O
                  </Link>
                </h2>
                <h2>
                  <Link
                    to="/argatin"
                    className="text-[12px] underline underline-offset-1 "
                    onClick={handleLinkClick}
                  >
                    Argatin
                  </Link>
                </h2>
                <h2>
                  <Link
                    to="/skinco"
                    className="text-[12px] underline underline-offset-1 "
                    onClick={handleLinkClick}
                  >
                    Skinco
                  </Link>
                </h2>


                <h2><Link to="/loreal" className="text-[12px] underline underline-offset-1 " onClick={handleLinkClick}>Loreal</Link></h2>
              </div>
              </div>

            <Link
              to={{
                pathname: "/hair-care",
              }}
              className="text-[16px] font-medium pb-2 lg:text-[23px] flex justify-start item-center  border-b border-white"
              onClick={handleLinkClick}
            >
              Haircare
            </Link>

            <Link
              to={{
                pathname: "/skin-care",
              }}
              className="text-[16px] font-medium pb-2 lg:text-[23px] flex justify-start item-center  border-b border-white"
              onClick={handleLinkClick}
            >
              Skincare
            </Link>

            <Link
              to={{
                pathname: "/distributer",
              }}
              className="text-[16px] font-medium pb-2 lg:text-[23px] flex justify-start item-center  border-b border-white"
              onClick={handleLinkClick}
            >
              Distribution
            </Link>

            <Link
              to={{
                pathname: "/contactus",
              }}
              className="text-[16px] font-medium pb-2 lg:text-[23px] flex justify-start item-center  border-b border-white"
              onClick={handleLinkClick}
            >
              Contact us
            </Link>

            <Link
              to={{
                pathname: "/product-combo",
              }}
              className="text-[16px] font-medium  lg:text-[23px] flex justify-start item-center  border-b border-white"
              onClick={handleLinkClick}
            >
              Product combo
            </Link>
         
          </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default SmallNavbar