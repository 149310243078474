import "./CustomInput.css"
const NewInput = ({name,label,value,onChange ,type="text"}) => {
  return (
    <>
  {/* From Uiverse.io by mrhyddenn */}
  <div className="relative overflow-hidden border rounded-[.25rem] border-[#b0b0b0]">
  <input
    type={type}
    id={label}
    name={name}
    value={value}
    required
    onChange={onChange}
    
    className="block  px-2.5 pb-1.5 pt-5 w-full text-sm text-gray-900 bg-gray-50   appearance-none  focus:outline-none focus:ring-0  peer"
    placeholder=" "
  />
  <label
    htmlFor={label}
    className="absolute text-sm text-gray-500  duration-300 transform -translate-y-3 scale-75 top-3 z-10 origin-[0] start-2.5   peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-[.5rem] rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto"
  >
    {label}
  </label>
</div>


</>

  )
}

export default NewInput